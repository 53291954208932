<template>
  <div class="weight">
      <breadCrumb :siderContent="siderName" />
      <div class="use myForm">
        <span class="kai">开启称重:</span>
          <a-radio-group v-model="form.use">
            <a-radio  :value="1">启用</a-radio>
            <a-radio  :value="2">禁用</a-radio>
          </a-radio-group>
      </div>
      <div class="install">
        <!-- <div class="heng"></div> -->
        <div class="font">基本设置</div>
        <!-- <div class="heng kuan"></div> -->
      </div>
     
      <a-form-model :model="form" ref="ruleForm" :rules="rules"  :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="厂家" prop="factory">
          <a-select v-model="form.factory" placeholder="请选择厂家">
            <a-select-option value="1">
              大华
            </a-select-option>
            <a-select-option value="2">
              顶尖
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="类型" prop="type">
          <a-select v-model="form.type" placeholder="请选择类型">
            <a-select-option value="2">
              智能一体秤
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="COM" prop="com">
          <a-select v-model="form.com" placeholder="请选择COM端口">
            <a-select-option value="COM1">
             COM1
            </a-select-option>
            <a-select-option value="COM2">
             COM2
            </a-select-option>
            <a-select-option value="COM3">
             COM3
            </a-select-option>
            <a-select-option value="COM4">
             COM4
            </a-select-option>
            <a-select-option value="COM5">
             COM5
            </a-select-option>
            <a-select-option value="COM6">
             COM6
            </a-select-option>
            <a-select-option value="COM7">
             COM7
            </a-select-option>
            <a-select-option value="COM8">
             COM8
            </a-select-option>
            <a-select-option value="COM9">
             COM9
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="波特率" prop="potter">
          <a-select v-model="form.potter" placeholder="设置波特率">
            <a-select-option value=1200>
              1200
            </a-select-option>
            <a-select-option value=2400>
              2400
            </a-select-option>
            <a-select-option value=4800>
              4800
            </a-select-option>
            <a-select-option value=9600>
              9600
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <div class="searchForm">
           <a-button @click="onSubmit" class="searchBtn">保存设置</a-button >
        </div>
       
    </a-form-model>
   
  </div>
</template>
<script>

 import breadCrumb from "@/components/BreadCrumb/BreadCrumb";
export default {
  data() {
    return {
      siderName:"通讯秤设置",
      form:{
        use:2,//1启用2禁用
        factory:undefined,//1大华2顶尖
        type:undefined,//1AI智能称2 智能一体称
        com:undefined,//1 COM1 2 COM2
        potter:undefined,//波特率
      },
      rules: {
        factory: [{ required: true, message: '请选择厂家', trigger: 'change' }],
        type:[{ required: true, message: '请选择类型', trigger: 'change' }],
        com:[{ required: true, message: '请选择COM端口', trigger: 'change' }],
        potter:[{ required: true, message: '请设置波特率', trigger: 'change' }],
      },
      labelCol: { span: 2 },
      wrapperCol: { span: 10 },

    }
  },
  components: {
    breadCrumb
  },
  mounted() {
    if(JSON.parse(localStorage.getItem('install'))){
      this.form=JSON.parse(localStorage.getItem('install'))
    }else{
      this.form={use:2,factory:undefined,type:undefined,com:undefined,potter:undefined,}
    }
  },
  updated() {
    console.log(this.form)
  },
  computed: {},
  watch: {},
  methods: {
    onSubmit(){
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          localStorage.setItem('install',JSON.stringify(this.form))
          this.$message.success('保存设置成功')
          console.log(JSON.stringify(this.form))
        } else {
          return false;
        }
      });
    }
  },
};
</script>
<style lang="less" scoped>
  .weight{
     width: 100%;
    background: #f8f8ff;
    .use{
      padding-left:1.6vh ;
      .kai{
        display: inline-block;
        margin-right:2vw;
        margin-top:3.3vh;
        margin-bottom: 3.2vh;
        font-size: 1.8vh;
      }
    }
    .install{
      font-size: 2vh;
      line-height: 3.1vh;
      margin-bottom: 2.7vh;
      display: flex;
      .heng{
        height:1px;
        width:2vw;
        background:#d0d0d0 ;
        line-height: 3.1vh;
        margin-top: 1.5vh;
      }
      .font{
        width:6.25vw;
        text-align: center;
        margin-left: 1.6vh;
      }
      .kuan{
        width:75vw !important;
      }
    }
    .ant-form{
      padding-left:1.6vh ;
    }
    .searchForm{
      margin-top:8vh;
      margin-left: 2vw;

    }
    /deep/.ant-form-item{
      display: flex;
      width:80vw;
      margin-bottom: 1.6vh;
      label{
        color:#505050;
      }
    }
    /deep/.ant-form-item-control-wrapper{
       width:19vw;
    }
    /deep/.ant-radio-wrapper{
      span{
        font-size: 1.8vh;
      }
    /deep/.ant-form-item-required{
      margin-right: 2vh;
    } 
    /deep/.ant-select-selection__rendered{
       font-size: 1.8vh !important;
    }
    }
  }
</style>
